.sectionProperties {
  width: 100%;
}

.car1 {
  position: absolute;
  display: block;
  max-width: 1045px;
  left: 0;
  z-index: 1;
}

.backgroundCarousel {
  width: 1035px;
  height: 690px;
  background: transparent;
  background: linear-gradient(
    bottom,
    rgba(255, 255, 255, 255) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(45, 79, 101, 0.7) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  background: -ms-linear-gradient(
    bottom,
    rgba(45, 79, 101, 0.7) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(46, 80, 103, 0.7) 30%,
    rgba(255, 255, 255, 0) 40%
  );
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#550000FF, endColorstr=#550000FF);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#ffffffff);
}

.backgroundCarousel img {
  z-index: -1;
}

.backgroundCarousel div {
  width: 100%;
}

.backgroundCarousel:hover {
  cursor: pointer;
}

.backgroundCarouselContent {
  display: flex;
  flex-direction: row;
  padding-left: 21px;
}

.backgroundCarouselImg div {
  border-radius: 50%;
  position: relative;
  bottom: 83px;
}

.proprietyText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: initial;
  padding-left: 30px;
  position: relative;
  bottom: 160px;
  z-index: 0;
  color: #ffffff;
}

.phaseCity {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
}

.phaseCity span:not(:first-child) {
  font-size: 12px;
  padding-left: 6px;
}

.typologyDesc {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.parallelogramSpan {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 21px;
  width: auto;
  transform: skew(20deg);
}

.parallelogramSpan span {
  display: inline-block;
  transform: skewX(-20deg);
  padding: 0 9px;
}

.carouselContent {
  height: 847px;
  position: relative;
  z-index: 2;
  padding: 115px 0;
  width: 70%;
}

.prevArrow {
  padding-top: 60px;
  left: 47%;
  top: 100%;
  width: 30;
  height: 30;
  z-index: 10;
  transition: transform 0.25s ease;
  position: absolute;
}

.prevArrow:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.nextArrow {
  padding-top: 60px;
  right: 45%;
  top: 100%;
  width: 30;
  height: 30;
  z-index: 10;
  position: absolute;
  transition: transform 0.25s ease;
}

.nextArrow:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.carouselContent :global(div.slick-slide) {
  margin: 0 20px;
}

.carouselContent :global(div.slick-slider) {
  height: 440px;
}

.carouselLink img:hover {
  cursor: pointer;
}

.parallelogram {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 128px;
  width: 880px;
  height: 690px;
  transform: skew(20deg);
  background: #ffffff;
}

.parallelogramDetail {
  width: 15px;
  height: 30px;
  transform: skew(25deg);
  position: relative;
  top: 6px;
  background: #d72e3f;
  margin-right: 20px;
}

.callText {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}

.carMob {
  display: none;
}
.carCont {
  display: flex;
  width: 100%;
  padding: 0 22px;
}

@media (max-width: 1672px) {
  .callText {
    padding: 80px 0px !important;
  }
}
@media (max-width: 1600px) {
  .callText {
    padding: 80px 0px !important;
  }
  .parallelogram {
    z-index: -1;
    width: 680px;
    height: 692px;
    right: 140px;

    transform: skew(20deg);
    background: #ffffff;
  }
}

@media (max-width: 1400px) {
  .carouselContent :global(div.slick-slide) {
    margin: 0 20px;
  }

  .carouselContent :global(div.slick-slider) {
    height: 440px;
  }

  .carouselContent {
    max-width: 749px;
    width: 100%;
  }

  .carDesktop {
    width: 63% !important;
  }

  .nextArrow {
    padding-top: 50px;
    right: 17%;
    top: 100%;
    width: 30;
    height: 30;
    z-index: 10;
    position: absolute;
    transition: transform 0.25s ease;
  }

  .prevArrow {
    padding-top: 50px;
    left: 70%;
    top: 100%;
    width: 30;
    height: 30;
    z-index: 10;
    transition: transform 0.25s ease;
    position: absolute;
  }
}

@media (max-width: 1340px) {
  .carDesktop {
    display: none;
  }
  .carMob {
    width: 100%;
    display: block;
    flex-direction: row;
    margin-bottom: 50px;
  }
  .callText {
    padding: 80px 0px;
  }

  .prevArrow {
    left: 68% !important;
  }

  .nextArrow {
    right: 20% !important;
  }

  .carouselContent {
    /* right: 269px !important; */
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .parallelogram {
    display: none;
  }
  .carouselContent {
    padding: 0 !important;
  }

  .callText {
    padding: 60px 0 !important;
    gap: 312px !important;
  }

  .prevArrow {
    left: 48% !important;
  }

  .nextArrow {
    right: 43% !important;
  }

  .carouselContent {
    right: -50px !important;
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .callText {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 21px !important;
    height: auto !important;
  }

  .parallelogramDetail {
    margin-right: 15px;
    top: 3px;
    width: 12px;
    height: 21px;
  }

  .callText h2 {
    font-size: 25px !important;
  }

  .button {
    right: 0 !important;
  }

  .carouselContent {
    right: -9px !important;
  }

  .prevArrow {
    display: none;
  }

  .nextArrow {
    display: none;
  }
}

.callText div:first-child {
  display: flex;
  flex-direction: row;
  font-size: 35px;
  color: var(--blue-text);
  font-weight: 500;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  right: 35px;
  align-items: center;
  border: 1px solid #8e9598;
  height: 50px;
  padding: 0 9px;
  transition: 0.2s;
}

.button:hover {
  background-color: rgba(18, 35, 60, 0.2);
  cursor: pointer;
}

.button:hover img {
  transform: scale(1.05);
}

.button a {
  padding-right: 30px;
  text-decoration: none;
  color: var(--blue-text);
  font-size: 13px;
  font-weight: 500;
}
