.proprietyContainer {
  border-bottom: 4px solid #ca3140;
  width: 354px!important;
  height: 500px;

  background: transparent;
  background: linear-gradient(
    bottom,
    rgba(255, 255, 255, 255) 0%,
    rgba(0, 0, 0, 0) 60%
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(45, 79, 101, 0.7) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  background: -ms-linear-gradient(
    bottom,
    rgba(45, 79, 101, 0.7) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(46, 80, 103, 0.7) 30%,
    rgba(255, 255, 255, 0) 40%
  );
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#550000FF, endColorstr=#550000FF);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#ffffffff);
}

.proprietyContainer:hover {
  cursor: pointer;
}

.proprietyImg {
  z-index: -1;
}

.proprietyText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: initial;
  padding-left: 30px;
  position: relative;
  bottom: 130px;
  z-index: 0;
  color: #ffffff;
}

.phaseCity {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
}

.phaseCity span:not(:first-child) {
  font-size: 12px;
  padding-left: 6px;
}

.typologyDesc {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.parallelogramSpan {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 21px;
  width: auto;
  transform: skew(20deg);
}

.parallelogramSpan span {
  display: inline-block;
  transform: skewX(-20deg);
  padding: 0 9px;
}

@media (max-width: 400px) {
  .proprietyContainer {
    width: 100%;
  }
}
