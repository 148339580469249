.bg {
  position: relative;
  min-height: 508px;
  display: flex !important;
  align-items: flex-end;
}

.prevArrow {
  position: absolute;
  right: calc((100% - 1140px) / 2 + 58px);
  top: 85%;
  z-index: 10;
  transition: transform 0.25s ease;
}

.nextArrow {
  right: calc((100% - 1140px) / 2);
  top: 85%;
  z-index: 10;
  position: absolute;
  transition: transform 0.25s ease;
}

.prevArrow:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.nextArrow:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.dots {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 2px solid #ffffff;
  padding-bottom: 3px;
}

.ulPos {
  list-style-type: none;
  display: flex;
  position: absolute;
  top: 50%;
  right: 3%;
}

.ulPos li {
  padding-bottom: 7px;
}

.ulPos :global(li.slick-active) .dots {
  border: 2px solid var(--red-hover);
}

.bannerContent {
  position: relative;

  display: flex;
  padding-left: calc((100vw - 1140px) / 2);
}

.parallelogram {
  position: relative;
  width: 370px;
  height: 390px;
  margin-left: 72px;
  transform: skew(24deg);
  background: #ca3140;
  opacity: 0.9;
}

.bannerText {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  padding-top: 60px;
  padding-left: 132px;
  color: #ffffff;
}

.bannerText h1 {
  width: 460px;
  font-size: 53px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 20px;
}

.bannerText span {
  max-width: 215px;
  margin-bottom: 40px;
}

.bannerText div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  align-items: center;
  border: 1px solid #ffffff;
  height: 50px;
  margin: auto;
  padding: 0 9px;
  transition: 0.2s;
}

.bannerText div:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.bannerText div:hover img {
  transform: scale(1.05);
}

.bannerText div a {
  padding-right: 30px;
  text-decoration: none;
  color: #ffffff;
}

/* .bg {
  background: linear-gradient(bottom, rgba(45, 79, 101, .7) 30%, rgba( 255, 255, 255, 0 ) 60% );
}

.bg img {
  z-index: -1;
} */
.imgMob {
  display: none !important;
}
@media (max-width: 1400px) {
}

@media (max-width: 1208px) {
}

@media (max-width: 1024px) {
  .imgMob {
    display: flex !important;
  }
  .imgDesktop {
    display: none !important;
  }
  .bannerContent {
    width: 100%;
    padding: 0 22px;
  }
  .bannerText {
    padding-left: 132px;
  }
}

@media (max-width: 600px) {
  .prevArrow {
    display: none;
  }

  .bannerContent {
    width: 100%;
    padding: 0;
  }

  .bannerText {
    padding-left: 82px;
  }

  .nextArrow {
    display: none;
  }

  .parallelogram {
    position: relative;
    width: 260px;
    height: 320px;
    transform: skew(20deg);
    background: #ca3140;
    opacity: 0.9;
  }

  .bannerText h1 {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .prevArrow {
    display: none;
  }

  .nextArrow {
    display: none;
  }

  .parallelogram {
    position: relative;
    width: 250px;
    height: 320px;
    transform: skew(20deg);
    background: #ca3140;
    opacity: 0.9;
  }

  .bannerText h1 {
    font-size: 30px;
  }
  .bannerText {
    padding-left: 62px;
  }

  .bannerText span {
    padding-bottom: 30px;
  }
}
