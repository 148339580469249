.parallelogramDetail {
  width: 15px;
  height: 30px;
  transform: skew(25deg);
  position: relative;
  margin-left: 8px;
  top: 6px;
  background: #d72e3f;
  margin-right: 20px;
}

.callText {
  display: flex;
  flex-direction: row;
}

.callText h2 {
  font-size: 35px;
  color: var(--blue-text);

  margin-bottom: 0;
}
.callText h2 * {
  font-size: 35px;
  color: var(--blue-text);
  line-height: 1;
  margin-bottom: 0;
}

.description {
  padding-top: 24px;
  position: relative;
  font-size: 20px;
  padding-left: 40px;
  color: var(--gray-text);
  margin-bottom: 40px;
}



.cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  gap: 20px;
}

.triangleTopright {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 300px 600px 0;
  border-color: transparent #d72e3f transparent transparent;
  position: relative;
  float: right;
  bottom: 220px;
}

.sectionHighlights footer {
  width: 100%;
  padding: 90px 247px 0;
}

.parallelogram {
  width: 330px;
  height: 112px;
  transform: skew(25deg);
  background: linear-gradient(to right, rgba(0, 46, 71), rgba(12, 99, 146));
}

.backgroundDetail {
  width: 740px;
  height: 289px;
  display: grid;
  left: 90px;
  top: 340px;
  background-image: url("/icons/square.webp");
  background-repeat: repeat;
  grid-template-columns: repeat(17, 1fr);
  position: absolute;
  z-index: -1;
}

.parallelogramBackgroundDetail {
  width: 12px;
  height: 25px;
  transform: skew(25deg);
  position: relative;
  background: #f4f3f3;
}

.bgCard {
  background: url("/images/faixa simbolo.png") no-repeat bottom right;
  position: relative;
}

@media (max-width: 1672px) {
}

@media (max-width: 1400px) {
  .bgCard {
    background-size: 15% !important;
    background-position-y: 100px !important;
  }
}

@media (max-width: 1024px) {
  .description {
    padding: 0 59px !important;
  }

  .bgCard {
    background-size: 13% !important;
    background-position-y: 200px !important;
  }
}

@media (max-width: 1000px) {
  .sectionHighlights {
    margin-top: 90px;
  }

  .callText {
    padding: 0 22px;
  }
  .description {
    padding: 0 22px;
    margin-top: 35px;
  }
  .cards {
    flex-direction: column;
    align-items: center;

    /* right: 115px; */
  }

  .backgroundDetail {
    width: 100%;
    height: 289px;
    left: 0;
  }

  .sectionHighlights footer {
    width: 100%;
    padding: 90px 22px;
  }

  .parallelogram {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .parallelogramDetail {
    position: relative;
    margin-right: 0;
    top: 12px;
    left: 36px;
    width: 30px;
    height: 21px;
  }

  .cards {
    gap: 30px;
  }

  .parallelogram {
    height: 30px;
  }

  .callText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .callText b, .callText span {
    font-size: 25px !important;
    line-height: 1;
  }

  .description {
    justify-content: center;
    text-align: center;
    padding-bottom: 30px !important;
  }

  .description p {
    font-size: 16px;  
  }

  .bgCard {
    background-size: 13% !important;
    background-position-y: 260px !important;
  }

  .callText span {
    font-size: 25px !important;
  }

  .sectionHighlights footer {
    width: 100%;
    padding: 0px 22px !important;
    z-index: -1;
  }
}


