.container {
  align-items: center;
  background: rgba(0, 30, 60);
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.image:after {
  background: rgba(0, 30, 60, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: baseline;
  z-index: 0;
  gap: 10px;
  padding: 20px 0;
}

.bgImg {
  z-index: -1;
}

.contentText {
  display: flex;
}

/* .contentText strong {
  color: var(--red-text);
} */

.contentText  {
  color: #ffffff;
  font-size: 18px;

  font-weight: 600;

  margin-bottom: -10px;
}

.form form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.formCol {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.inputCont input {
  min-height: 35px;
  padding-left: 12px;
}


/* .inputCont:first-of-type {
  margin-bottom: 35px;
} */

.spanField {
  display: flex;
  flex-direction: column;
  color: var(--red-text);
  gap: 6px;
}

.spanField span {
  font-size: 14px;
}

.spanField input {
  border-bottom: 2px solid #d72e3f !important;
}

.spanDisplayNone {
  display: none;
}

.optional {
  line-height: 1;
  min-height: 38px;
  color: #ffffff;
  margin-bottom: 35px;
}

.form form input {
  width: 220px;
  border: 0;
  border-bottom: 2px solid #ffffff;
  color: #65686c;
}

.form form select {
  width: 69px;
  border: 0;
  border-bottom: 2px solid #ffffff;
  color: #65686c;
}

.form form input::placeholder {
  color: #65686c;
}

.form form select::placeholder {
  color: #65686c;
}

.bg {
  position: relative;
  /* min-height: 36px; */
}



.button {
  width: 130px;
  height: 35px;
  background: var(--red-text);
  border: 0;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 9px 15px;
  transition: 0.2s;
}

.button button {
  width: 100%;
}

.inputCont select {
  min-height: 35px;
  padding-left: 12px;
  padding-top: 6px;
  
  /* webkit*/
}

.inputCont select::placeholder {
  color: #000;
  /* webkit*/
}

.inputCont option {
  text-align: left;
  /* reset to left*/
}

.button:hover {
  cursor: pointer;
  filter: brightness(.9);
}

.button:hover img {
  transform: scale(1.05);
}

@media (max-width: 1400px) {
  .button {
    transform: translate(0);
  }
}

@media (max-width: 1200px) {
  .container {
    position: unset;
  }

  .form {
    width: 100%;
  }
  .form form {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .form form input {
    width: 100%;
  }

  .form form select {
    width: 100%;
  }

  .bg {
    height: auto;
    padding: 50px 0;
    min-height: auto;
  }

  .content {
    padding: 0 22px;
    margin: 22px 0;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .contentText {
    justify-content: center;
    font-size: 20px;
  }
  .contentText p {
    text-align: center;
  }

  .formCol:first-of-type {
    margin-right: 0;
  }

  .formCol {
    margin-right: 0;
    gap: 0;
  }

  .formCol {
    width: 100%;
    flex-direction: column;
  }

  .inputCont {
    margin-bottom: 30px;
  }

  .optional {
    margin-bottom: 30px;
  }

  .button {
    transform: translate(0);
    /* margin-top: 35px; */
  }

  .contentText {
    width: 100%;
    display: flex;
    margin-bottom: 35px;
  }

  .contentText p {
  }
  .contentText p br {
    display: none;
  }

  .button {
    width: 100%;
  }
}
